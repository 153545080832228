import { render, staticRenderFns } from "./RegistrationOfferIncreasePage.vue?vue&type=template&id=5f4f37b0&scoped=true"
import script from "./RegistrationOfferIncreasePage.vue?vue&type=script&lang=ts"
export * from "./RegistrationOfferIncreasePage.vue?vue&type=script&lang=ts"
import style0 from "./RegistrationOfferIncreasePage.vue?vue&type=style&index=0&id=5f4f37b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f4f37b0",
  null
  
)

export default component.exports