import { MULTI_LOAN_ROUTES, PROFILE_ROUTES, REGISTRATION_ROUTES, ROUTE } from '@/router/routes_type';
import { agreements } from '@/store/modules/agreements';
import { applications } from '@/store/modules/application';
import { DocumentService } from '@/services/document';
import { Application, APPLICATION_STATUS, OfferType } from '@/api/models/application';
import { Agreement, AGREEMENT_CONTRACT_STATUS, ONLINE_SIGNING_TYPE } from '@/api/models/agreements';
import { client } from '@/store/modules/client';
import { isVerigramActive } from '@/utils/verigram';
import { documents } from '@/store/modules/documents';
import { Calculation } from '@/components/calculator/Calculator.vue';

const ROUTE_STORAGE_KEY = 'route';
const CALCULATION_STORAGE_KEY = 'calculation';

export async function getRoute(): Promise<ROUTE> {
  await applications.initGetActiveApplication();
  await applications.initGetApplications();

  if (isVerigramActive() && !applications.hasApplicationNotInFinaleState) {
    if (
      !client.livelinessVerificationFinished ||
      (!client.livelinessVerificationReject && !client.usernameVerificationFinished)
    ) {
      return REGISTRATION_ROUTES.VERIGRAM;
    }
  }

  await documents.initDocuments();

  if (!(await isFullPersonalInfo())) {
    return REGISTRATION_ROUTES.ADDITIONAL_INFO;
  }

  await client.initProfileDisbursements();
  if (!client.hasDisbursements || !client.isSigningMethodFilled) {
    return REGISTRATION_ROUTES.DISBURSEMENT_TYPE_PAGE;
  }

  if (
    !(
      DocumentService.hasIdDocuments() &&
      ((client.usernameVerificationConfirmed && DocumentService.hasVerigramDocuments()) || DocumentService.hasSelfie())
    )
  ) {
    return REGISTRATION_ROUTES.DOCUMENTS_PAGE;
  }

  if (applications.getActiveApplication) {
    const applicationRoute = getRouteByApplication(applications.getActiveApplication);
    if (applicationRoute) {
      return applicationRoute;
    }

    await agreements.initGetAgreementList({ force: true });
    const agreement = agreements.getAgreements.find(
      agreement => agreement.applicationId === applications.getActiveApplication?.id
    );

    if (agreement) {
      const agreementRoute = getRouteByAgreement(agreement);
      if (agreementRoute) {
        return agreementRoute;
      }
    }

    const storedRoute = getStoredRoute();
    if (storedRoute) {
      return storedRoute;
    } else if (
      agreements.getActiveAgreements.some(agreement => agreement.contractStatus !== AGREEMENT_CONTRACT_STATUS.ISSUED)
    ) {
      return PROFILE_ROUTES.MY_LOAN_PAGE;
    }

    return MULTI_LOAN_ROUTES.CALCULATOR;
  }

  if (applications.applications.length > 0) {
    return getStoredRoute() ?? MULTI_LOAN_ROUTES.CALCULATOR;
  }

  if (retrieveCalculation()) {
    return getStoredRoute() ?? REGISTRATION_ROUTES.SUMMARY_PAGE;
  }

  return REGISTRATION_ROUTES.CALCULATOR;
}

export function getRouteByAgreement(agreement: Agreement): ROUTE | null {
  if (
    [
      AGREEMENT_CONTRACT_STATUS.PREPARED,
      AGREEMENT_CONTRACT_STATUS.SIGNED,
      AGREEMENT_CONTRACT_STATUS.SCHEDULED,
      AGREEMENT_CONTRACT_STATUS.DRAFT
    ].includes(agreement.contractStatus)
  ) {
    if (
      agreement.signingMethod?.type === ONLINE_SIGNING_TYPE ||
      agreement.documents[0]?.details.details?.signOnlineLink
    ) {
      return REGISTRATION_ROUTES.AGREEMENT_ONLINE_SIGNING_PAGE;
    }

    return REGISTRATION_ROUTES.AGREEMENT_SIGNING_PAGE;
  }

  return null;
}

export function getRouteByApplication(application: Application): ROUTE | null {
  switch (application.status) {
    case APPLICATION_STATUS.SUBMITTED:
    case APPLICATION_STATUS.DECISION:
    case APPLICATION_STATUS.DECISION_ERROR:
    case APPLICATION_STATUS.AML_CHECK:
    case APPLICATION_STATUS.BANK_STATEMENT_CHECK:
    case APPLICATION_STATUS.BANK_STATEMENT_CONFIRMED:
    case APPLICATION_STATUS.BANK_STATEMENT_REUPLOADED:
      return REGISTRATION_ROUTES.DECISION_PAGE;
    case APPLICATION_STATUS.BANK_STATEMENT_REUPLOAD:
      return REGISTRATION_ROUTES.INCOME_REUPLOAD_PAGE;
    case APPLICATION_STATUS.OFFERED:
      return application.offer?.type ? getRouteByOfferType(application.offer.type) : null;
    case APPLICATION_STATUS.CONFIRMED:
      return REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE;
    case APPLICATION_STATUS.REJECTED:
    case APPLICATION_STATUS.CANCELLED:
      return REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE;
    case APPLICATION_STATUS.APPROVED:
    default:
      return null;
  }
}

export function getRouteByOfferType(type: OfferType): REGISTRATION_ROUTES {
  switch (type) {
    case OfferType.BASE:
      return REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE;
    case OfferType.INCREASE:
      return REGISTRATION_ROUTES.OFFER_INCREASE_PAGE;
    case OfferType.DECREASE:
      return REGISTRATION_ROUTES.OFFER_DECREASE_PAGE;
  }
}

async function isFullPersonalInfo(): Promise<boolean> {
  await client.initProfile({ force: true });
  if (!client.personalDataFilled) {
    return false;
  }
  if (!client.addressesFilled) {
    return false;
  }
  if (applications.applications.length > 0) {
    if (!client.workInfoFilled) {
      return false;
    }
  } else {
    if (!client.financesDataFilled || !DocumentService.hasBankStatement()) {
      return false;
    }
  }

  return client.familyAdditionalInfoFilled;
}

export function getStoredRoute(): ROUTE | null {
  const route = localStorage.getItem(craftKey(ROUTE_STORAGE_KEY));

  return route ? (route as ROUTE) : null;
}

export function storeRoute(route: ROUTE) {
  localStorage.setItem(craftKey(ROUTE_STORAGE_KEY), route);
}

export function clearRoute() {
  localStorage.removeItem(craftKey(ROUTE_STORAGE_KEY));
}

export function storeCalculation(calculation: Calculation) {
  localStorage.setItem(craftKey(CALCULATION_STORAGE_KEY), JSON.stringify(calculation));
}

export function retrieveCalculation(): Calculation | null {
  const item = localStorage.getItem(craftKey(CALCULATION_STORAGE_KEY));
  if (!item) {
    return null;
  }

  let data: Calculation | null = null;
  try {
    data = JSON.parse(item) || null;
  } catch (_) {
    localStorage.removeItem(craftKey(CALCULATION_STORAGE_KEY));

    return null;
  }

  if (!data) {
    return null;
  }

  const isValid =
    typeof data.loan === 'object' &&
    typeof data.product === 'object' &&
    typeof data.loan?.amount === 'number' &&
    typeof data.loan?.term === 'number' &&
    typeof data.product?.id === 'string' &&
    typeof data.product?.revision === 'object';

  if (isValid) {
    return data;
  }

  localStorage.removeItem(craftKey(CALCULATION_STORAGE_KEY));

  return null;
}

export function clearCalculation() {
  localStorage.removeItem(craftKey(CALCULATION_STORAGE_KEY));
}

function craftKey(key: string): string {
  return key + client.profile.id;
}
