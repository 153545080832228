
import { Component, Vue, Prop } from 'vue-property-decorator';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsForm from '@/components/registration/Form.vue';
import {
  FORM_ERRORS,
  longText,
  phoneNumberFormat,
  required,
  shortText
} from '@/components/registration/settings/rules';
import LmsMultiInput from '@/components/registration/FieldMultiUpload.vue';
import { OTHER_DOCUMENT_TYPES, UploadSource } from '@/api/models/document';
import { mobilePhoneMask } from '@/components/registration/settings/mask';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import FieldMultiUpload from '@/components/registration/FieldMultiUpload.vue';
import { isEqual } from 'lodash-es';
import { uploadDocument } from '@/api/document';
import { debtSettlementApplication } from '@/store/modules/debtSettlementApplication';
import { Agreement } from '@/api/models/agreements';
import { LmsFormState } from '@/components/registration/models/form';
import {
  DebtSettlementApplicationInput,
  DebtSettlementApplicationStatus,
  FINAL_STATUSES
} from '@/api/models/debtSettlementApplication';
import { DebtSettlementFormData, DocumentsUpload } from '@/views/profile/DebtSettlement/DebtSettlement';

const REVIEW_TRANSITION = 'review';

@Component({
  name: 'DebtSettlementApplicationForm',
  methods: { shortText, longText, phoneNumberFormat, required, noSpacePattern },
  components: { FieldMultiUpload, LmsMultiInput, LmsInput, LmsForm }
})
export default class extends Vue {
  @Prop({ required: true }) loading!: boolean;
  @Prop({ required: true }) agreement!: Agreement;
  @Prop({ required: true }) onlyUpdateDocuments!: boolean;
  @Prop({ required: true }) value!: DebtSettlementFormData;

  form: LmsFormState;
  files: UploadSource[] = [];
  uploadedFiles: UploadSource[] = [];
  appealText: string = this.value.appealText;
  contactPhone: string = this.value.contactPhone;

  get mobilePhoneMask() {
    return mobilePhoneMask;
  }

  mounted() {
    this.form = this.$refs.form as LmsFormState;
  }

  get filesEquals() {
    return isEqual(this.files, this.uploadedFiles);
  }

  payload(documentsList: string[]): DebtSettlementApplicationInput {
    if (!this.agreement) {
      throw new Error('Agreement is not loaded');
    }

    return {
      payload: {
        appealText: this.appealText.trim(),
        contactPhone: this.contactPhone,
        agreementNumber: this.agreement.number,
        loanId: this.agreement.id,
        documentsList: documentsList
      }
    };
  }

  upload(fileList: UploadSource[]) {
    this.files = fileList;
  }

  async uploadFiles() {
    this.form.validateFields();

    if (!this.form.fieldsValid() || !this.onlyUpdateDocuments) {
      return;
    }

    this.$emit('loading');
    await debtSettlementApplication.initGetDebtSettlementApplications({ force: true });
    if (
      debtSettlementApplication.lastDebtSettlementApplication &&
      debtSettlementApplication.lastDebtSettlementApplication.status !==
        DebtSettlementApplicationStatus.DOCUMENTS_REQUESTED
    ) {
      this.$emit('already-reviewed');
      return;
    }

    const upload = await this.uploadDocuments({
      applicationId: debtSettlementApplication.lastDebtSettlementApplication?.id ?? '',
      clientId: this.agreement?.clientId ?? '',
      loanId: this.agreement?.id ?? ''
    });

    if (upload.success) {
      await this.reviewApplication();
      this.$emit('uploaded');
    } else {
      this.$emit('error');
    }
  }

  async reviewApplication() {
    this.$emit('loading');

    try {
      await debtSettlementApplication.initTransitDebtSettlementApplication({
        id: debtSettlementApplication.lastDebtSettlementApplication?.id ?? '',
        transition: REVIEW_TRANSITION
      });

      this.$notify.success(this.$t('DEBT_SETTLEMENT.APPLICATION_SUBMITTED_MESSAGE').toString());
      this.$emit('submitted');
    } catch (response) {
      if (response.status === 400) {
        this.form.setErrors(response.data?.violations ?? undefined);
      }

      this.$notify.error(this.$t('NOTIFICATION.DOCUMENT_UPLOAD.ERROR').toString());
      this.$emit('error');
    }
  }

  async uploadDocuments(metadata: Record<string, string> = {}): Promise<DocumentsUpload> {
    const uploadPromises = this.files.map((source: UploadSource) => {
      return uploadDocument({
        type: OTHER_DOCUMENT_TYPES.DEBT_SETTLEMENT,
        file: source.raw,
        metadata: metadata
      });
    });

    const result = await Promise.allSettled(uploadPromises);
    const allRejected = result.every(result => result.status === 'rejected');
    const someRejected = result.find(result => result.status === 'rejected') as PromiseRejectedResult | undefined;

    if (someRejected) {
      if (someRejected?.reason?.message.includes('timeout')) {
        this.$notify.error(this.$t('NOTIFICATION.DOCUMENT_UPLOAD.ERROR').toString());
      } else {
        this.$notify.error(this.$t(`FORM.${FORM_ERRORS.FIELD_ERROR_FILE_TYPE}`).toString());
      }
      this.$emit('error');
    }

    const documentsList = [] as string[];
    result.forEach(r => {
      if (r.status === 'fulfilled' && r.value?.data?.id) {
        documentsList.push(r.value.data.id as string);
      }
    });

    return {
      success: uploadPromises.length > 0 ? !allRejected : true,
      documentsList: documentsList
    } as DocumentsUpload;
  }

  async submit() {
    this.$emit('loading');

    await debtSettlementApplication.initGetDebtSettlementApplications({ force: true });
    if (
      debtSettlementApplication.lastDebtSettlementApplication &&
      !FINAL_STATUSES.includes(debtSettlementApplication.lastDebtSettlementApplication.status)
    ) {
      this.$emit('application-exist');
      return;
    }

    const documentsList = [] as string[];
    if (!this.onlyUpdateDocuments) {
      const upload = await this.uploadDocuments({
        clientId: this.agreement?.clientId ?? '',
        loanId: this.agreement?.id ?? ''
      });

      documentsList.push(...upload.documentsList);
      if (!upload.success) {
        this.$emit('error');
        return;
      }
    }

    try {
      await debtSettlementApplication.initCreateDebtSettlementApplication(this.payload(documentsList));

      this.$notify.success(this.$t('DEBT_SETTLEMENT.APPLICATION_SUBMITTED_MESSAGE').toString());
      this.$emit('submitted');
    } catch (response) {
      if (response.status === 400) {
        this.form.setErrors(response.data?.violations ?? undefined);
      }

      this.$notify.error(this.$t('NOTIFICATION.CALCULATOR.ERROR').toString());
      this.$emit('error');
      console.error('Failed to submit debt settlement.');
    }
  }
}
