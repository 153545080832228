export enum APPLICATION_STATUS {
  SUBMITTED = 'submitted',
  CONFIRMED = 'confirmed',
  DECISION = 'decision',
  DECISION_ERROR = 'decision_error',
  BANK_STATEMENT_CHECK = 'bank_statement_check',
  BANK_STATEMENT_CONFIRMED = 'bank_statement_confirmed',
  BANK_STATEMENT_REUPLOAD = 'bank_statement_reupload',
  BANK_STATEMENT_REUPLOADED = 'bank_statement_reuploaded',
  AML_CHECK = 'aml_check',
  OFFERED = 'offered',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled'
}

export interface ApplicationPayloadInput {
  loanAmount: string;
  term: number;
  startDate?: string;
  paymentDate?: string;
}

export interface ApplicationPayload {
  productId: string;
  input: ApplicationPayloadInput;
  marketingMetrics?: Record<string, unknown>;
  purpose: string;
}

export enum AdditionalActionTransition {
  REUPLOAD_BANK_STATEMENT = 'reupload_bank_statement'
}

export interface ActionPayload {
  transition: AdditionalActionTransition;
  payload: Record<string, unknown>;
}

export interface ApplicationClientInputMetadata {
  loanAmount: string;
  term: number;
  startDate: string;
  paymentDay: number;
  paymentDate: string;
  capitalizedAmount: string;
  paymentAmount: string;
}

export interface ApplicationClientInput {
  loanAmount: string;
  term: number;
}

export interface ApplicationPreComputation {
  metadata: ApplicationClientInputMetadata;
  companyId: string;
  brandId: string;
  amounts: {
    averagePayment: string;
    capitalizedComponents: string;
    capitalizedLoanAmount: string;
    fullRepaymentAmount: string;
    regularComponents: string;
    annuityPayment: string;
  };
}

export interface OfferVariation {
  loanAmount: string;
  paymentAmount: string;
  term: number;
}

export enum OfferType {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  BASE = 'base'
}
export interface ApplicationOffer {
  variations: OfferVariation[];
  type: OfferType;
}

export interface Application {
  id: string;
  productId: string;
  clientId: string;
  loanAmount: string;
  term: string;
  status: APPLICATION_STATUS;
  offer?: ApplicationOffer;
  clientInputMetadata: ApplicationClientInput;
  preComputation: ApplicationPreComputation;
}
