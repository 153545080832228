
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import { SEND_PROGRESS, SKIP_ROUTE_CHECK } from '@/router/routes';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { applications } from '@/store/modules/application';
import { getRouteByApplication } from '@/router/router';

const POOLING_INTERVAL = 5000;

@Component({
  name: 'RegistrationDecisionLoadingPage',
  components: { ApplicationPage }
})
export default class RegistrationDecisionLoadingPage extends Mixins(CheckRoutingMixin) {
  private checkRouteTimeout: number;

  @Watch('progress', { immediate: true })
  setProgress() {
    progress.set('85');
  }

  created() {
    this.checkApprovedApplication();
  }

  checkApprovedApplication() {
    this.checkRouteTimeout = setTimeout(async () => {
      if (!(await applications.initGetActiveApplication())) {
        return;
      }

      if (applications.getActiveApplication) {
        const route = getRouteByApplication(applications.getActiveApplication);
        if (route && route !== REGISTRATION_ROUTES.DECISION_PAGE) {
          return this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS, SKIP_ROUTE_CHECK: SKIP_ROUTE_CHECK });
        }
      }

      return this.checkApprovedApplication();
    }, POOLING_INTERVAL);
  }

  beforeDestroy() {
    clearTimeout(this.checkRouteTimeout);
  }
}
