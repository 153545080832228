
import { Component, Mixins } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { applications } from '@/store/modules/application';
import { progress } from '@/store/modules/progress';
import OfferCard from '@/components/registration/OfferCard.vue';
import ApplicationOfferMixin from '@/mixins/ApplicationOffer.vue';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { getRouteByApplication } from '@/router/router';
import { SEND_PROGRESS } from '@/router/routes';

@Component({
  components: { OfferCard, ApplicationPage }
})
export default class RegistrationOfferIncreasePage extends Mixins(ApplicationOfferMixin) {
  acceptIncreaseLoading = false;
  acceptBaseLoading = false;

  mounted() {
    progress.set('85');
  }

  get baseOffer() {
    return applications.baseOffer;
  }

  get increaseOffer() {
    return applications.proposedOfferVariation;
  }

  async acceptBase() {
    if (this.acceptBaseLoading) {
      return;
    }

    this.acceptBaseLoading = true;
    await applications.initGetActiveApplication();

    if (applications.getActiveApplication) {
      const route = getRouteByApplication(applications.getActiveApplication);
      if (route && route !== REGISTRATION_ROUTES.OFFER_INCREASE_PAGE) {
        return this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });
      }
    }

    await this.performAction(
      applications.confirmApplication,
      loading => (this.acceptBaseLoading = loading),
      REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE
    );
  }

  async acceptIncrease() {
    if (this.acceptIncreaseLoading) {
      return;
    }

    this.acceptIncreaseLoading = true;
    await applications.initGetActiveApplication();
    if (applications.getActiveApplication) {
      const route = getRouteByApplication(applications.getActiveApplication);
      if (route && route !== REGISTRATION_ROUTES.OFFER_INCREASE_PAGE) {
        return this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });
      }
    }

    await this.performAction(
      applications.acceptOffer,
      loading => (this.acceptIncreaseLoading = loading),
      REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE
    );
  }
}
